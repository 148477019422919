<template>
  <Layout class="Dashboard-layout">
    <BHeader>
      <el-form
        ref="form"
        :rules="rules"
        :model="formData"
        :inline="true"
      >
        <el-form-item prop="groupIds">
          <el-select
            v-model="formData.groupIds"
            multiple
            filterable
            remote
            reserve-keyword
            collapse-tags
            placeholder="请输入关键词, 查询账号组"
            :remote-method="remoteSearch"
            :loading="remoteLoading"
            value-key="groupId"
            style="width: 420px;"
          >
            <el-option
              v-for="item in groupList"
              :key="item.groupId"
              :label="item.groupName"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <template #right>
        <el-button
          type="primary"
          @click="handleManageAccountGroup"
        >
          管理账号组
        </el-button>
      </template>
    </BHeader>
    <div
      v-loading="loading"
      class="wrapper"
    >
      <StackedAreaChart
        ref="chart"
        height="auto"
        :legend-list="legendList"
        :chart-data="chartData"
        :x-axis-data="xAxisData"
      />
    </div>
  </Layout>
</template>

<script>
import StackedAreaChart from '@/views/resources/account-group/components/StackedAreaChart'
import { queryAccountGroupList, gameAccountLeaseCount, getMostAccountGroup } from '@/api/rentAccount'
import { cloneDeep } from 'lodash'
import { format } from 'fecha'
import { appIdMap } from '@/utils/selectOptions.js'

const translateList2Map = (list) => {
  return list.reduce((map, item) => {
    map[item.groupId] = item.groupName + '-' + item.groupId
    return map
  }, {})
}

export default {
  name: 'Dashboard',
  components: {
    StackedAreaChart
  },
  data () {
    const groupIdsValidator = (rule, value, callback) => {
      if (!value.length) {
        callback(new Error('账号组必选'))
      } else {
        callback()
      }
    }
    const dateRangeValidator = (rule, value, callback) => {
      if (!value || !value.length || !value[0]) {
        callback(new Error('时间范围必选'))
      } else {
        callback()
      }
    }
    return {
      formData: {
        groupIds: [],
        dateRange: [
          new Date().getTime() - 24 * 60 * 60 * 1000,
          new Date().getTime()
        ]
      },
      chartData: [],
      xAxisData: [],
      legendList: [],
      remoteLoading: true,
      groupList: [],
      rules: {
        groupIds: [
          { required: true, validator: groupIdsValidator }
        ],
        dateRange: [
          { required: true, validator: dateRangeValidator }
        ]
      },
      loading: false
    }
  },
  created () {
    this.getMostAccountGroup()
  },
  methods: {
    handleManageAccountGroup () {
      this.$router.push({
        name: 'RentAccount'
      })
    },
    remoteSearch (groupName) {
      if (groupName !== '') {
        this.remoteLoading = true
        queryAccountGroupList({ groupName, size: 20 })
          .then(res => {
            this.groupList = (res.data.list || []).map(item => {
              return {
                groupId: item.groupId,
                groupName: ((appIdMap[item.appId] || {}).name || '未知') + '-' + item.groupName
              }
            })
          })
          .finally(() => {
            this.remoteLoading = false
          })
      } else {
        this.groupList = []
      }
    },
    getMostAccountGroup () {
      getMostAccountGroup({})
        .then(res => {
          Object.keys(res.data.list || [])
            .forEach(groupName => {
              const groupId = res.data.list[groupName]
              this.groupList.push({
                groupName,
                groupId
              })
            })
          this.formData.groupIds = this.groupList
          this.handleSearch()
        })
    },
    handleSearch () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const formData = cloneDeep(this.formData)
          const groupIds = formData.groupIds.map(item => { return item.groupId })
          const [start, end] = formData.dateRange
          const startTime = Math.floor(start / 1000)
          const endTime = Math.floor(end / 1000)
          const tempMap = translateList2Map(formData.groupIds)
          this.loading = true
          gameAccountLeaseCount({
            groupIds,
            startTime,
            endTime
          })
            .then(res => {
              this.xAxisData = []
              this.chartData = []
              this.legendList = []
              ;(res.data.time || []).forEach(time => {
                this.xAxisData.push(format(new Date(time), 'MM-DD HH:mm'))
              })
              Object.keys((res.data.data || [])).forEach((groupId, index) => {
                this.legendList.push(tempMap[groupId] || '未知-' + index)
                this.chartData.push(res.data.data[groupId].counts || [])
              })
              this.$nextTick(() => {
                this.$refs.chart.setOptions()
              })
              if (!res.data.data && !res.data.time) {
                this.$message({
                  message: '未查询到可用数据',
                  type: 'warn',
                  duration: 4000
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="less">
  .Dashboard-layout{
    .layout-content{
      display: flex;
      flex-direction: column;
      .wrapper{
        flex: 1;
        display: flex;
        .chart{
          flex: 1;
        }
      }
    }
  }
</style>
